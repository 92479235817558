<template>
  <!-- if not logined -->
  <v-container class="pa-2" style="max-width: 600px">
    <div class="pt-0 px-1" style="font-size: 14px">
      <a class="text-decoration-none" @click="back_to_home">
        <v-icon>mdi-chevron-left-circle</v-icon>
      </a>
    </div>

    <v-card class="py-5 mt-5" elevation="0">
      <v-img max-width="250px " style="margin: 0 auto" src="@/assets/deliver.jpeg"></v-img>

      <v-card-text class="text-center px-0">
        ご購入を完成させるには、
        <br />
        アカウントでのログインが必要となります。
        <br />
        <br />
        ご購入の手続きが完了されると、LINE公式から
        <br />
        ご注文の受付確認メッセージが届きます。
        <br />
        <br />
        <!-- 配達やお受取に関しても、<br />LINE公式にてお知らせいたします。<br /> -->
      </v-card-text>
    </v-card>

    <div app style="font-weight: bold; max-width: 600px; margin: 0 auto">
      <v-row class="mt-2 mb-1 px-5 py-4 green accent-4 white--text" @click="login_clicked">
        <v-spacer></v-spacer>
        <v-img max-height="35" max-width="45" src="../../public/line.png"></v-img>
        <span class="pt-2 pl-2">LINEでログイン</span>
        <v-spacer></v-spacer>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <span class="mt-2 mb-1 px-5 py-4" v-bind="attrs" v-on="on">
              <v-icon color="green darken-2" style="margin-bottom: 4px">mdi-domain</v-icon>
              <span class="accent-4 green--text">法人のお客様</span>
            </span>
          </template>
          <v-card>
            <v-card-title class="text-h5">法人向けサービスは一時停止中です🙇‍♂️</v-card-title>
            <v-card-text class="text-caption">
              <p>いつもご利用いただきありがとうございます！</p>
              <p>
                現在、法人向けサービスは一時停止しております。サービスの質を向上させるためのメンテナンスを実施中です。ご不便をおかけし誠に申し訳ございませんが、ご理解のほど宜しくお願い申し上げます。
              </p>
              <p>
                以下のメールアドレスまで、何かご質問等がございましたら、お気軽にお問い合わせください。
              </p>
              <p style="font-weight: bold; margin-left: 4px; margin-bottom: 15px">
                info@recosele.com
              </p>
              <div>
                再開の日付が決定いたしましたら、速やかにご連絡させていただきます。
                今後ともご愛顧賜りますようお願い申し上げます。
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">戻る</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <span class="mt-2 mb-1 px-5 py-4" v-bind="attrs" v-on="on">
              <v-icon color="green darken-2" style="margin-bottom: 4px">mdi-domain</v-icon>
              <span class="accent-4 green--text">法人のお客様</span>
            </span>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5 mt-2">ログイン</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="account"
                      :rules="[rules.required]"
                      label="アカウント*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.max]"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-1"
                      label="パスワード*"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <small class="red--text">{{ this.message }}</small>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">キャンセル</v-btn>
              <v-btn color="green darken-1" text @click="login">ログイン</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-row>
    </div>
  </v-container>
</template>

<!-- if logined -->
<!-- redirect -->

<script>
  import Methods from '@/api/method.js';

  export default {
    name: 'Login',

    data: () => ({
      dialog: false,
      account: '',
      password: '',
      message: '',
      showPassword: false,
      rules: {
        required: (value) => !!value || 'Required.',
        max: (v) => v.length < 30 || 'Max 30 characters',
        // emailMatch: () => `The email and password you entered don't match`,
      },
    }),
    created() {
      if (this.$store.state.user.logged_in == true) {
        this.$router.push('/');
      }
    },
    methods: {
      back_to_home: function () {
        this.$router.push('/');
        // this.$store.commit("open_cart");
        // alert(this.$store.state.is_cart_open)
      },

      login_clicked: function () {
        let url =
          'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655963535&redirect_uri=' +
          // 'https%3A%2F%2Fwww.google.com'+
          'https%3A%2F%2Fwww.recosele.com%2Fredirect' +
          '&max_age=0' +
          '&state=' +
          this.$store.state.user.login_state +
          '&bot_prompt=aggressive' +
          '&scope=profile%20openid&nonce=09876xyz';

        console.log(url);
        window.location.href = url;
      },
      login: async function () {
        if (this.account && this.password) {
          try {
            const { data } = await Methods.AccountLogin({
              account: this.account,
              password: this.password,
            });

            if (data._id) {
              this.$store.commit('user/login_usr', {
                user_id: data._id,
                friendship: true,
                is_corporation: true,
                is_adult: data.is_adult
              });
              this.$router.push('/');
            } else throw data;
          } catch (error) {
            this.message = error.message;
          }
        }
      },
    },
  };
</script>

<style scoped></style>
